import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import sections from "../sections/sections"
import Fallback from "../fallback"

export default function Homepage(props) {
  //console.debug('Homepage - props', props)

  //orchard
  const siteSectionDataHomepageQuery = useStaticQuery(graphql`
    query siteSectionDataHomepageQuery {
      siteData {
        landingPage {
          contentItems {
            ... on LandingPage {
              contentItemId
              contentType
              displayText
              sections {
                contentItems {
                  ... on SectionHero {
                    contentItemId
                    contentType
                    displayText
                    commonPageSection {
                      dOMID
                      sectionPadding
                      textAlign
                      textColor
                      backgroundColor
                      backgroundImage {
                        urls
                        imageAbsoluteUrls
                        gatsbyLocalFile {
                          publicURL
                        }
                        gatsbyImageData
                      }
                      backgroundVideo
                    }
                    contentCollection {
                      contentItems {
                        ... on ContentHeadline {
                          contentItemId
                          contentType
                          displayText
                          variationType
                          textAlign
                          preheader
                          content {
                            html
                          }
                          subheader
                        }
                        ... on ContentButton {
                          contentItemId
                          contentType
                          displayText
                          variationType
                          textColor
                          link {
                            text
                            url
                          }
                          icon {
                            iconName
                            iconLabelPosition
                          }
                          linkText
                          commonContentLink {
                            linkContentText
                            dOMID
                            internalLink {
                              url
                            }
                            externalLink {
                              url
                            }
                            mediaPDF {
                              imageAbsoluteUrls
                              gatsbyLocalFile {
                                publicURL
                              }
                            }
                          }
                        }
                        ... on ContentDivider {
                          contentItemId
                          contentType
                          displayText
                          hidden
                          dividerMargin
                        }
                      }
                    }
                  }
                  ... on SectionHomepageOurSolutions {
                    contentItemId
                    contentType
                    displayText
                    commonPageSection {
                      dOMID
                      textColor
                      backgroundColor
                      backgroundImage {
                        urls
                        imageAbsoluteUrls
                        gatsbyLocalFile {
                          publicURL
                        }
                        gatsbyImageData
                      }
                    }
                    contentCollection {
                      contentItems {
                        ... on ContentHeadline {
                          contentItemId
                          contentType
                          displayText
                          variationType
                          textAlign
                          preheader
                          content {
                            html
                          }
                          subheader
                        }
                        ... on ContentAccordionImage {
                          contentItemId
                          contentType
                          displayText
                          accordionPanels {
                            contentItems {
                              ... on ContentAccordionImagePanel {
                                contentItemId
                                contentType
                                displayText
                                commonPageSection {
                                  dOMID
                                  textColor
                                  backgroundColor
                                  backgroundImage {
                                    urls
                                    imageAbsoluteUrls
                                    gatsbyImageData
                                  }
                                }
                                headline
                                contentImage {
                                  urls
                                  imageAbsoluteUrls
                                  gatsbyImageData
                                }
                                icon {
                                  iconName
                                  iconLabelPosition
                                }
                                contentCollection {
                                  contentItems {
                                    ... on ContentText {
                                      contentItemId
                                      contentType
                                      displayText
                                      content {
                                        html
                                      }
                                    }
                                    ... on ContentButton {
                                      contentItemId
                                      contentType
                                      displayText
                                      variationType
                                      textColor
                                      link {
                                        text
                                        url
                                      }
                                      icon {
                                        iconName
                                        iconLabelPosition
                                      }
                                      linkText
                                      commonContentLink {
                                        linkContentText
                                        dOMID
                                        internalLink {
                                          url
                                        }
                                        externalLink {
                                          url
                                        }
                                        mediaPDF {
                                          imageAbsoluteUrls
                                          gatsbyLocalFile {
                                            publicURL
                                          }
                                        }
                                      }
                                    }
                                    ... on ContentDivider {
                                      contentItemId
                                      contentType
                                      displayText
                                      hidden
                                      dividerMargin
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        ... on ContentGrid {
                          contentItemId
                          contentType
                          displayText
                          variationType
                          divided
                          centered
                          verticalAlign
                          gridRows {
                            contentItems {
                              ... on ContentGridRow {
                                contentItemId
                                contentType
                                displayText
                                reversed
                                columns {
                                  contentItems {
                                    ... on ContentGridRowColumn {
                                      contentItemId
                                      contentType
                                      displayText
                                      commonPageSection {
                                        dOMID
                                        textColor
                                        backgroundColor
                                        backgroundImage {
                                          urls
                                          imageAbsoluteUrls
                                          gatsbyLocalFile {
                                            publicURL
                                          }
                                          gatsbyImageData
                                        }
                                      }
                                      width
                                      contentCollection {
                                        contentItems {
                                          ... on ContentHeadline {
                                            contentItemId
                                            contentType
                                            displayText
                                            variationType
                                            textAlign
                                            preheader
                                            content {
                                              html
                                            }
                                            subheader
                                          }
                                          ... on ContentText {
                                            contentItemId
                                            contentType
                                            displayText
                                            content {
                                              html
                                            }
                                            richText {
                                              html
                                            }
                                          }
                                          ... on ContentButton {
                                            contentItemId
                                            contentType
                                            displayText
                                            variationType
                                            textColor
                                            link {
                                              text
                                              url
                                            }
                                            icon {
                                              iconName
                                              iconLabelPosition
                                            }
                                            linkText
                                            commonContentLink {
                                              linkContentText
                                              dOMID
                                              internalLink {
                                                url
                                              }
                                              externalLink {
                                                url
                                              }
                                              mediaPDF {
                                                imageAbsoluteUrls
                                                gatsbyLocalFile {
                                                  publicURL
                                                }
                                              }
                                            }
                                          }
                                          ... on ContentDivider {
                                            contentItemId
                                            contentType
                                            displayText
                                            hidden
                                            dividerMargin
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        ... on ContentCardHorizontal {
                          contentItemId
                          contentType
                          displayText
                          variationType
                          divided
                          centered
                          verticalAlign
                          imageAlign
                          commonPageSection {
                            dOMID
                            textAlign
                            textColor
                            backgroundColor
                            backgroundImage {
                              urls
                            }
                            backgroundSize
                            backgroundPosition
                          }
                          contentCollection {
                            contentItems {
                              ... on ContentHeadline {
                                contentItemId
                                contentType
                                displayText
                                variationType
                                sticky
                                textAlign
                                preheader
                                content {
                                  html
                                }
                                subheader
                              }
                              ... on ContentText {
                                contentItemId
                                contentType
                                displayText
                                content {
                                  html
                                }
                                richText {
                                  html
                                }
                              }
                              ... on ContentButton {
                                contentItemId
                                contentType
                                displayText
                                variationType
                                textColor
                                link {
                                  text
                                  url
                                }
                                icon {
                                  iconName
                                  iconLabelPosition
                                }
                                linkText
                                commonContentLink {
                                  linkContentText
                                  dOMID
                                  internalLink {
                                    url
                                  }
                                  externalLink {
                                    url
                                  }
                                  mediaPDF {
                                    imageAbsoluteUrls
                                    gatsbyLocalFile {
                                      publicURL
                                    }
                                  }
                                }
                              }
                              ... on ContentDivider {
                                contentItemId
                                contentType
                                displayText
                                hidden
                                dividerMargin
                              }
                            }
                          }
                        }
                        ... on ContentDivider {
                          contentItemId
                          contentType
                          displayText
                          hidden
                          dividerMargin
                        }
                      }
                    }
                  }
                  ... on SectionCTA {
                    contentItemId
                    contentType
                    displayText
                    commonPageSection {
                      dOMID
                      textColor
                      backgroundColor
                      backgroundImage {
                        urls
                        imageAbsoluteUrls
                        gatsbyImageData
                      }
                    }
                    variationType
                    contentCollection {
                      contentItems {
                        ... on ContentHeadline {
                          contentItemId
                          contentType
                          displayText
                          variationType
                          textAlign
                          preheader
                          content {
                            html
                          }
                          subheader
                        }
                        ... on ContentButton {
                          contentItemId
                          contentType
                          displayText
                          variationType
                          textColor
                          link {
                            text
                            url
                          }
                          icon {
                            iconName
                            iconLabelPosition
                          }
                          linkText
                          commonContentLink {
                            linkContentText
                            dOMID
                            internalLink {
                              url
                            }
                            externalLink {
                              url
                            }
                            mediaPDF {
                              imageAbsoluteUrls
                              gatsbyLocalFile {
                                publicURL
                              }
                            }
                          }
                        }
                        ... on ContentDivider {
                          contentItemId
                          contentType
                          displayText
                          hidden
                          dividerMargin
                        }
                      }
                    }
                  }
                  ... on SectionHomepageTestimonials {
                    contentItemId
                    contentType
                    displayText
                    commonPageSection {
                      dOMID
                      textColor
                      backgroundColor
                      backgroundImage {
                        urls
                        imageAbsoluteUrls
                        gatsbyImageData
                      }
                    }
                    contentCollection {
                      contentItems {
                        ... on ContentHeadline {
                          contentItemId
                          contentType
                          displayText
                          variationType
                          textAlign
                          preheader
                          content {
                            html
                          }
                          subheader
                        }
                        ... on ContentSlider {
                          contentItemId
                          contentType
                          displayText
                          variationType
                          contentSliderSlides {
                            contentItems {
                              ... on ContentSliderSlide {
                                contentItemId
                                contentType
                                displayText
                                variationType
                                contentText
                                content2
                                content3
                                image {
                                  urls
                                  imageAbsoluteUrls
                                  gatsbyImageData(width: 150)
                                }
                              }
                            }
                          }
                        }
                        ... on ContentDivider {
                          contentItemId
                          contentType
                          displayText
                          hidden
                          dividerMargin
                        }
                      }
                    }
                  }
                  ... on SectionBasic {
                    contentItemId
                    contentType
                    displayText
                    commonPageSection {
                      dOMID
                      sectionPadding
                      textColor
                      backgroundColor
                      backgroundImage {
                        urls
                        imageAbsoluteUrls
                        gatsbyLocalFile {
                          publicURL
                        }
                        gatsbyImageData
                      }
                    }
                    contentCollection {
                      contentItems {
                        ... on ContentHeadline {
                          contentItemId
                          contentType
                          displayText
                          variationType
                          textAlign
                          preheader
                          content {
                            html
                          }
                          subheader
                        }
                        ... on ContentButton {
                          contentItemId
                          contentType
                          displayText
                          variationType
                          textColor
                          link {
                            text
                            url
                          }
                          icon {
                            iconName
                            iconLabelPosition
                          }
                          linkText
                          commonContentLink {
                            linkContentText
                            dOMID
                            internalLink {
                              url
                            }
                            externalLink {
                              url
                            }
                            mediaPDF {
                              imageAbsoluteUrls
                              gatsbyLocalFile {
                                publicURL
                              }
                            }
                          }
                        }
                        ... on ContentGrid {
                          contentItemId
                          contentType
                          displayText
                          variationType
                          verticalAlign
                          gridRows {
                            contentItems {
                              ... on ContentGridRow {
                                contentItemId
                                contentType
                                displayText
                                reversed
                                columns {
                                  contentItems {
                                    ... on ContentGridRowColumn {
                                      contentItemId
                                      contentType
                                      displayText
                                      commonPageSection {
                                        dOMID
                                        textColor
                                        backgroundColor
                                        backgroundImage {
                                          urls
                                          imageAbsoluteUrls
                                          gatsbyLocalFile {
                                            publicURL
                                          }
                                          gatsbyImageData
                                        }
                                      }
                                      width
                                      contentCollection {
                                        contentItems {
                                          ... on ContentHeadline {
                                            contentItemId
                                            contentType
                                            displayText
                                            variationType
                                            textAlign
                                            preheader
                                            content {
                                              html
                                            }
                                            subheader
                                          }
                                          ... on ContentButton {
                                            contentItemId
                                            contentType
                                            displayText
                                            variationType
                                            textColor
                                            link {
                                              text
                                              url
                                            }
                                            icon {
                                              iconName
                                              iconLabelPosition
                                            }
                                            linkText
                                            commonContentLink {
                                              linkContentText
                                              dOMID
                                              internalLink {
                                                url
                                              }
                                              externalLink {
                                                url
                                              }
                                              mediaPDF {
                                                imageAbsoluteUrls
                                                gatsbyLocalFile {
                                                  publicURL
                                                }
                                              }
                                            }
                                          }
                                          ... on ContentDivider {
                                            contentItemId
                                            contentType
                                            displayText
                                            hidden
                                            dividerMargin
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        ... on ContentCardHorizontal {
                          contentItemId
                          contentType
                          displayText
                          variationType
                          divided
                          centered
                          verticalAlign
                          imageAlign
                          commonPageSection {
                            dOMID
                            textAlign
                            textColor
                            backgroundColor
                            backgroundImage {
                              urls
                            }
                            backgroundSize
                            backgroundPosition
                          }
                          contentCollection {
                            contentItems {
                              ... on ContentHeadline {
                                contentItemId
                                contentType
                                displayText
                                variationType
                                sticky
                                textAlign
                                preheader
                                content {
                                  html
                                }
                                subheader
                              }
                              ... on ContentText {
                                contentItemId
                                contentType
                                displayText
                                content {
                                  html
                                }
                                richText {
                                  html
                                }
                              }
                              ... on ContentButton {
                                contentItemId
                                contentType
                                displayText
                                variationType
                                textColor
                                link {
                                  text
                                  url
                                }
                                icon {
                                  iconName
                                  iconLabelPosition
                                }
                                linkText
                                commonContentLink {
                                  linkContentText
                                  dOMID
                                  internalLink {
                                    url
                                  }
                                  externalLink {
                                    url
                                  }
                                  mediaPDF {
                                    imageAbsoluteUrls
                                    gatsbyLocalFile {
                                      publicURL
                                    }
                                  }
                                }
                              }
                              ... on ContentDivider {
                                contentItemId
                                contentType
                                displayText
                                hidden
                                dividerMargin
                              }
                            }
                          }
                        }
                        ... on ContentDivider {
                          contentItemId
                          contentType
                          displayText
                          hidden
                          dividerMargin
                        }
                      }
                    }
                  }
                  ... on SectionHomepageNews {
                    contentItemId
                    contentType
                    displayText
                    commonPageSection {
                      dOMID
                      textAlign
                      textColor
                      backgroundColor
                      backgroundImage {
                        urls
                        imageAbsoluteUrls
                        gatsbyImageData
                      }
                    }
                    contentCollection {
                      contentItems {
                        ... on ContentHeadline {
                          contentItemId
                          contentType
                          displayText
                          variationType
                          textAlign
                          preheader
                          content {
                            html
                          }
                          subheader
                        }
                        ... on ContentDivider {
                          contentItemId
                          contentType
                          displayText
                          hidden
                          dividerMargin
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  if (typeof window !== 'undefined') {
    document.body.id = 'homepage';
  }

  //console.debug('Homepage - siteSectionDataHomepageQuery', siteSectionDataHomepageQuery)

  return (
    <>
      {siteSectionDataHomepageQuery.siteData.landingPage.contentItems[0].sections.contentItems.map((block) => {
        //console.debug('block', block)
        const { contentItemId, contentType, 
          //...componentProps 
        } = block
        //console.debug('sections[contentType]', sections[contentType])
        const Component = sections[contentType] || Fallback
        return <Component key={contentItemId} renderSource={'Homepage'} {...block} />
        //return <Component key={id} {...componentProps} />
      })}
    </>
  )
}
