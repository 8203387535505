// Layout Selector

// ----------------------
// IMPORTS

/* NPM */

// React
import React from 'react';
//import React, { Suspense } from 'react';

// Layouts
import TritonDefaultLayout from './triton-default/default-layout';
// Homepages
import TritonDefaultHomepage from '../components/triton-default/homepage/homepage';

/* // Layouts
//const DefaultBasicLayout = React.lazy(() => import('./default/basic-layout'));
const TritonDefaultLayout = React.lazy(() => import('./triton-default/default-layout'));
// Homepages
//const DefaultHomepage = React.lazy(() => import('../components/default/homepage/homepage'));
const TritonDefaultHomepage = React.lazy(() => import('../components/triton-default/homepage/homepage')); */


// ----------------------

const LayoutSelector = (props) => {
  //console.debug('LayoutSelector - props.layout_type', props.layout_type);
  return (
    <>
      {props.layout_type === 'triton_default' && <TritonDefaultLayout children={<TritonDefaultHomepage />} />}
    </>
  )
}

// const LayoutSelector = (props) => {
//   console.debug('LayoutSelector - props.layout_type', props.layout_type);
//   return (
//     <>
//       <Suspense fallback={<div>Loading...</div>}>
//         {/* {props.layout_type === 'default' && <DefaultBasicLayout children={<DefaultHomepage />} />} */}
//         {props.layout_type === 'triton_default' && <TritonDefaultLayout children={<TritonDefaultHomepage />} />}
//       </Suspense>
//     </>
//   )
// }

export default LayoutSelector;