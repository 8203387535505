import * as React from "react"
import { graphql, 
  //Script 
} from "gatsby"

import LayoutSelector from '../layouts'

//import Layouts from '../layouts/layouts'

//import DefaultHomepage from "../components/default/homepage/homepage"
//import TentpoleDefaultHomepage from "../components/tentpole-default/homepage/homepage"
import Seo from "../components/triton-default/seo"
import GatsbyGoogleTag from "../components/triton-default/gatsby-google-tag"

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => {
  return (
    <>
      <Seo />
      {/* onetrust */}
      {/* <Script type="text/javascript" src="https://cdn.cookielaw.org/consent/0d1f14aa-3072-48e2-9e28-7fb5de8c730b/OtAutoBlock.js"></Script>
      <Script src="https://cdn.cookielaw.org/consent/0d1f14aa-3072-48e2-9e28-7fb5de8c730b/otSDKStub.js" type="text/javascript" charset="UTF-8" data-domain-script="0d1f14aa-3072-48e2-9e28-7fb5de8c730b"></Script>
      <Script type="text/javascript">
        {`
          function OptanonWrapper() { }
        `}
      </Script> */}
      <GatsbyGoogleTag />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link rel="preload" as="style" href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&subset=latin&display=swap"></link>
      {/* <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        rel="preload"
        as="style"
        href="https://fonts.googleapis.com/css?family=Poppins:400,500,600&subset=latin&display=swap"
      /> */}
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&subset=latin&display=swap"
        media="print"
        //onload="this.media='all'"
      />
      <noscript>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&subset=latin&display=swap"
        />
      </noscript>
    </>
  )
}

const IndexPage = (props) => {
  //console.debug('IndexPage - props', props);

  const { siteData } = props.data;

  /* const data = useStaticQuery(graphql`
    query siteDataQuery {
      siteData {
        metaname
        layout_type {
          metaname
        }
      }
    }
  `);
  console.debug('data', data) */

  if (siteData?.layout?.contentItems && siteData.layout.contentItems.length !== 0) {
    const { layoutType } = siteData.layout.contentItems[0];
    /* let children
    switch (layoutType) {
      case 'default':
        children = <DefaultHomepage />
        break;
      case 'tentpole_default':
        //children = <h1>test</h1>
        children = <TentpoleDefaultHomepage />
        break;
      default:
        console.log(`Invalid Layout Type: ${layoutType}.`);
    } */
    return (
      <>
        <LayoutSelector key={layoutType} layout_type={layoutType} />
        {/* {React.createElement(Layouts[layoutType], {
          key: layoutType,
          children
        })} */}
      </>
    )
  } else {
    return (
      <>
        <h1>No Layout Type Found</h1>
      </>
    )
  }
}
export default IndexPage

// orchard query
export const query = graphql`
  {
    siteData {
      contentItemId
      contentType
      displayText
      layout {
        contentItems {
          ... on Layout {
            remoteTypeName
            contentItemId
            contentType
            displayText
            layoutType
            themeType
          }
        }
      }
    }
  }
`
/* // local data query
export const query = graphql`
  {
    siteData {
      contentItemId
      contentType
      displayText
      layout {
        contentItems {
          contentItemId
          contentType
          displayText
          layoutType
          themeType
        }
      }
    }
  }
` */